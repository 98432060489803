// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

// $primary: red;

// @import "~bootstrap/scss/bootstrap.scss";
@use "bulma/sass" with (
  $link: #edc72f,
  $primary: #591007,
);
@use "bulma/sass/utilities/mixins";

$primary: #591007;
$scale-factor: 0;

.has-text-centered { 
  div.is-flex {
    justify-content: center;
  }
}

img.image.logo {
  max-width: 42px;
}

.gallery {
  .cell {
    max-width: 600px;
  }
}

.navbar {
  .navbar-brand {
    align-items: center;
  }
}

.jumbotron {
  // should be relative path of the entry scss file
  background-image: url("../../vendors/images/sample.jpg");
  background-size: cover;
}

.title {
  font-family: "grand_paradisoregular", sans-serif;
}

.has-column-gap {
  gap: var(--bulma-column-gap)
}

.hero {
  .bg-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 360px;
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;

    @include mixins.tablet {
      min-height: 480px;
    }
  }

  .subtitle {
    font-family: "grand_paradiso_scriptregular", sans-serif;
  }

  .hero-body {
    display: flex;
    justify-content: center;
  
    &.v-align-top {
      align-items: flex-start;
    }
  
    &.v-align-bottom {
      align-items: flex-end;
    }
  
    &.v-align-middle {
      align-items: center;;
    }
  }
}

body.contact-page {
  .box.form-box {
    max-width: 640px;
  }
}

.c-has-hover-gradient {
  &:hover {
    scale: calc(1 + $scale-factor);
  
    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: linear-gradient(to bottom right,$primary,blue);
        opacity: .3;
        z-index: -1;
    }
  }

  &.card {
    &:hover {
      &::before {
        border-radius: var(--bulma-control-radius);
      }
    }
  }

  &.button {
    &:hover {
      &::before {
        border-radius: var(--bulma-control-radius);
      }
    }
  }
}

.is-256x256 {
  width: 256px;
  height: 256px;
}

.is-512x512 {
  width: 512px;
  height: 512px;
}

// PhotoSwipe Gallery Caption CSS
/* stylelint-disable */
.pswp__custom-caption {
  background: var(--bulma-dark-base);
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  text-align: center;
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

.hidden-caption-content {
  display: none;
}
/* styleline-enable */